import React from "react"
import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core"
//COMPONENTS
import BulletItem from "../components/bullet-item"
import Article from "../components/article"
import Carousel from "../components/carousel"
import NumericList from "../components/numeric-list"
import Section from "../components/section"
import HeroSection from "../components/hero-section"
import Testimonial from "../components/testimonial"
import ListCard from "../components/list-card"
import AboutImage from "./assets/Ivone-Foto-4.jpg"
import About from "../components/about"
import Card from "../components/card"
import Navigation from "../components/navigation"
import Newsletter from "../components/newsletter"

//IMAGES
import HeroImage from "./assets/POTENCIAL_20200315_171641-2048x969.jpg"
import ImageEstrategia from "./assets/pexels-pixabay-277124-2.jpg"
import ImageSensibilidad from "./assets/pexels-nataliya-vaitkevich-5201527-2.jpg"
import ImageAccion from "./assets/pexels-andre-furtado-5180962.jpg"
import ImageEquipo from "./assets/pexels-andrea-piacquadio-3830752.jpg"
import ImageAreas from "./assets/pexels-asad-photo-maldives-457881.jpg"
import ImageDistinto from "./assets/pexels-pixabay-373394.jpg"
import ImageOceanBlue from "./assets/pexels-magda-ehlers-636353.jpg"

//ICONS
import { RiMoneyEuroCircleLine } from "react-icons/ri"
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"
import { IoMdCheckmarkCircle } from "react-icons/io"
import { ImCircleRight } from "react-icons/im"
import { BsCheckCircle } from "react-icons/Bs"
import Footer from "../components/footer"

export default function PTCR() {
  const icon = <RiMoneyEuroCircleLine color="#269fb2" size="40px" />
  const bulletIcon1 = <IoIosArrowForward color="#269fb2" size="36px" />
  //const bulletIcon2 = <IoIosArrowForward size="20px" />
  const bulletIcon2 = <BsCheckCircle color="#269fb2" size="22px" />
  const bulletIcon3 = <IoMdCheckmarkCircle color="#269fb2" size="26px" />
  const bulletIcon4 = <ImCircleRight color="#269fb2" size="26px" />

  const numericListText = [
    "Es totalmente personalizado ya que, en nuestras sesiones de Coaching 1 a 1, nos enfocamos totalmente en ti, en tus necesidades y en los desafíos o bloqueos que se te presentan al momento. Con lo que notarás transformación y avance inmediatos.",
    "Incluye ejercicios de introspección y reflexión para que te conozcas mejor, identifiques cómo te estas auto-saboteando, reconozcas tus fortalezas y sepas utilizarlas mejor en tu vida para conseguir lo que quieres.",
    "Te lleva paso a paso por contenidos específicos, donde aprenderás herramientas que te serán de utilidad para toda la vida.",
    "Esta enfocado a la acción para que comiences a aplicar e integrar paso a paso lo aprendido. Por lo que notarás cambios rápidamente en tu situación.",
    "Incluye Autoevaluaciones y puntos de verificación, para que tu misma te des cuenta de tu transformación y de tu avance. Podrás observar y celebrar los resultados tangibles en tu vida. Esto te mantendrá totalmente motivada.",
    "Además nos enfocaremos en transformar y desarrollar tu nueva mentalidad, te reconocerás como la diseñadora y artista de tu vida.",
    "Y lo más importante, seremos un equipo. Contarás con mi apoyo personalizado y el respaldo de toda mi experiencia para acompañarte durante todo el programa, especialmente en los momentos de dudas e incertidumbre. Nos comunicaremos vía Chat y correo electrónico.",
  ]

  const TitleConfianza = ["Elevaras la Confianza en ti misma"]
  const ListConfianza = [
    "Confiarás firmemente en ti y dejarán de importarte las opiniones de los demás a cerca de lo que haces o no con tu vida.",
    "Vas a aprender a demostrarte con hechos que eres capaz de lograr todo lo que te propongas.",
    "Te sentirás segura de ti misma, de tu valor como persona y de todo lo que tienes para ofrecerle al mundo.",
    "Dejarás de auto-sabotearte y empezarás a tomar acción para lograr tus metas.",
    "Retomaras el control de tu vida y también aprenderás a fluir con ella y a disfrutarla como nunca antes.",
  ]
  const TitleDudas = ["Despejaras tus Dudas & Vencerás tus Miedos"]
  const ListDudas = [
    "Descubrirás todas esas ideas heredadas que limitan tu desarrollo y crecimiento y también sabrás por qué te tienen estancada.",
    "Romperás con esas limitaciones imaginarias que te impiden conectar con tus talentos y empezaras a usarlos para tu bienestar y provecho.",
    "Aprenderás a confiar en tu intuición y en tu guía interior.",
    "Te quitarás de encima el miedo, la incertidumbre y las dudas paralizantes.",
    "Vas a reconocerte como la creadora de tu vida, te sentirás super poderosa y confiarás en ti para manifestar la vida que quieres.",
  ]
  const TitleMetas = ["Te fijarás metas realistas y sabrás cómo alcanzarlas"]
  const ListMetas = [
    <span>
      Aprenderás a fijarte <strong>metas estratégicas</strong> y, basada en el
      conocimiento de ti misma, lograrás cumplirlas.
    </span>,
    "Terminarás, de una vez por todas, con esa sensación de frustración y estancamiento.",
    "Tomarás decisiones más fácilmente y sin sentir culpa ni remordimientos.",
    "Sentirás y observarás que por fin te estas moviendo y te va a encantar.",
    "Empezaras a tomar los primeros pasos para conseguir esos ingresos económicos que quieres lograr.",
  ]
  const TitleEmociones = ["Usarás tus Emociones como Guía"]
  const ListEmociones = [
    "Vas a descubrir qué es lo que TÚ quieres de verdad.",
    "Vas a aprender a liberarte de las expectativas sociales y familiares.",
    "Aprenderás a entender el mensaje que tus emociones tienen para ti y tomarás acción con intención basada en el conocimiento de ti misma.",
    "Aprenderás a reconocer y satisfacer tus necesidades personales antes que las de los demás.",
    "Vas a reconocer aquellas relaciones que te afectan negativamente y te alejarás de ellas.",
    "Encontrarás tu perfecto balance entre emoción, razón y acción.",
  ]
  const TitleTalentos = ["Desarrollaras tus Talentos y los usarás en tu Favor"]
  const ListTalentos = [
    "Reconocerás y aprenderás a usar tus talentos únicos y personales para lograr tus metas.",
    "Actuarás alineada con tus valores, habilidades y capacidades y te darás cuenta de que todo es más sencillo desde allí.",
    "Te sentirás mucho más satisfecha y plena haciendo lo que quieres hacer.",
    "Te sentirás motivada y llena de energía y le dirás ¡Adiós las dudas que te paralizaban!",
    "Vas a empezar a generar ingresos económicos en sintonía con todo eso que eres capaz de hacer y ofrecer.",
  ]
  const Title_SI = ["Es para ti sí."]
  const List_SI = [
    "Ya estas harta de sentirte frustrada e infeliz y no piensas tolerarlo más.",
    "Tienes bien claro que solo tú eres capaz de transformar tu situación y estas más que lista para hacer todo lo que se requiere.",
    "Estas cansada de sentir que tus decisiones y emociones dependen de lo que digan o piensen los demás.",
    "Estás decidida a tomar acción y empezar a utilizar tus talentos y emociones para alcanzar tus metas y en favor de tu propio bienestar.",
    "Estas abierta a nuevas ideas y a ponerlas en práctica para experimentar la diferencia.",
    "Necesitas acompañamiento personalizado, para compartir y comentar tus dudas y emociones a lo largo del proceso.",
    "Necesitas motivación adicional e instrucciones claras y concretas para ir integrando lo aprendido en tu vida paso a paso.",
    "Estas lista para dedicar mínimo tres horas semanales: una hora para las sesión personalizada de coaching y dos horas extra -mínimo- para realizar los ejercicios del programa.",
    "Piensas que la mejor inversión que puedes hacer es en ti misma.",
  ]
  const Title_NO = ["NO es para ti sí."]
  const List_NO = [
    "Te gustaría transformar tu vida pero no estas dispuesta a tomar acción.",
    "Quieres seguir haciendo las cosas a tu manera y no estas lista para integrar nuevos hábitos en tu vida.",
    "Prefieres quedarte en tu zona de confort, aunque te sientas insatisfecha.",
    "No crees que cambiar tu mentalidad pueda realmente transformar tu situación.",
    "Para ti es muy fácil integrar nuevas ideas y conceptos en tu vida diaria y no necesitas acompañamiento personalizado, ni motivación adicional.",
    "Te sientes super feliz y contenta con la vida que llevas en todos los aspectos.",
    "Piensas que es muy caro y no piensas que la mejor inversión eres tú misma.",
  ]
  const List_Incluye = [
    "Tres meses de Coaching.",
    "Nueve sesiones de Coaching personalizadas 1 a 1 de 60 minutos cada una.",
    "Workbook con ejercicios de reflexión semanales personalizados y adaptados a tu proceso. Para que te conozcas mejor y sepas interpretar tus señales de forma adecuada.",
    "Contenidos teóricos estratégicos para que integres mejor los aprendizajes del programa y los apliques en tu vida.",
    "Desafíos personalizados para pasar a la acción y empezar la transformación inmediatamente.",
    "Acompañamiento y asesoría vía email y Chat durante todo el programa, para resolver tus dudas y bloqueos,compartir y revisar tus avances. Y también para asegurarme de que avanzas con éxito y no te atascas en ningún momento.",
    "Hojas de trabajo de coaching para observar y transformar tu mentalidad. ",
    "Mapa Estratégico para que sepas en todo momento qué es lo que estamos trabajando y como eso te ayudará a lograr tus metas.",
    "Test de Autoevaluación, para que tu misma te des cuenta de tus avances y los celebres.",
    "Bibliografía, artículos, recomendaciones de libros y abundante material extra.",
  ]
  const Testimonio_1 = `Llevaba un largo tiempo mal conmigo mismo, no me aguantaba ni yo mismo. Vivía con resignación, frustración e insatisfecho totalmente con la vida que llevaba. 
  Me sentía perdido. Gracias al coaching con Ivone tengo más confianza en mí mismo, más fuerza y finalmente estoy tomando acción.
  He mejorado mis relaciones sociales y de pareja al entender que yo soy el responsable de todo y que tengo que tomar la responsabilidad y las riendas de mi vida en mis manos.
  Este coaching te ayuda a reconectar contigo mismo, a dar un paso hacia el interior. Creo que es el comienzo de un viaje apasionante que nunca acaba. ¡Muchas gracias por todo Ivone!`
  const TestName_1 = "Marcello Ortíz"

  const Testimonio_2 = `El programa The Confidence Revolution me ha cambiado la vida por completo y también conocer a Ivone.
  Este programa enseña mucho sobre autoconocimiento, sobre quien eres, los pensamientos que tienes que cada día que te están limitando.
  Con este programa logré dejar el trabajo que tenía, aunque era bastante estable, porque no me sentía identificada con él. Buscaba algo 
  con que ayudar a los demás y sentirme mucho más realizada y este programa me dio la confianza que necesitaba para atreverme.
  Al principio tenía mucho miedo, con el programa identifiqué mis fortalezas, mis valores y mis pasiones y fui capaz de enfrentar el miedo. 
  Finalmente tengo un nuevo trabajo donde puedo utilizar mis talentos y toda mi experiencia y me siento super entusiasmada con este nuevo comienzo. GRACIAS Ivone.`
  const TestName_2 = "Alma María Gómez."

  const Testimonio_3 = `Recomiendo totalmente The Confidence Revolution. Ivone es super estructurada, estratégica y también muy empática, todo el tiempo me sentí 
  comprendida y acompañada en este proceso. Además siempre me sentí con la libertad de expresar todo lo que pensaba y de no ser juzgada, yo creo que esto 
  fue fundamental para poder avanzar. Ya había tomado terapia antes, pero no me había sentido tan cómoda como con Ivone. 
  Además los retos semanales, los ejercicios de reflexión y el acompañamiento por Whatsapp, me ayudaron mucho para trabajar mis bloqueos en el momento 
  en que surgían. Ivone siempre estuvo allí a lo largo de todo este camino. De verdad Ivone muchísimas gracias! 
  Es gracias a todo lo que aprendí contigo que ahora me siento mucho más segura de mí misma y de lo que puedo lograr. 
  Recomiendo a Ivone 100%.`
  const TestName_3 = "Vero Pimentel"

  const Testimonio_4 = `The Confidence Revolution me ha hecho recordar cosas que había olvidado y que eran importantes en mi vida, 
  he transformado muchas ideas que me estaban limitando y que me tenían frustrada y super bloqueada. Después de años de batallar 
  finalmente he ordenado mi casa y me he deshecho de todo lo que ya no me servía y también he notado como mis relaciones 
  interpersonales han mejorado muchísimo. Me siendo mucho más feliz y a gusto con lo que soy. 
  He retomado mucho de lo que me gustaba cuando era más joven, lo he integrado en mi vida diaria y disfruto más cada día.`
  const TestName_4 = "Mary Roldán."

  const Testimonio_5 = `El programa The Confidence Revolution con Ivone cambió totalmente la forma de ver mi propia vida, aprendí mucho sobre mis propias fortalezas y debilidades 
  y me mostró perspectivas completamente nuevas para mi. No sabía que cambiar el enfoque y las interpretación que daba a las cosas que me pasaban 
  podía transformar tan radicalmente mis sentimientos y la forma en la que actuaba en mi día a día.
  Gracias al coaching con Ivone, me alejé de relaciones conflictivas, me cambié de trabajo, 
  dejé de hacer cosas  que me hacían daño porque ya no tuvo más sentido para mi y hasta mis amigas notaron que cambié.  
  Ahora me siento empoderada, capaz de lograr muchas cosas que antes veía imposibles. Gracias por tanto Ivone!`
  const TestName_5 = "Sandra Flores."

  const Testimonio_6 = `Siendo honesta, comencé este programa solo porque una amiga me lo recomendó, aunque yo estaba 
  super incrédula. No sabía que era eso del coaching y francamente no creí que me pudiera ayudar para nada. 
  Pero el coaching con Ivone marcó un antes y un después en mi vida. Estoy totalmente agradecida a mi amiga por recomendármelo y a 
  Ivone por ser tan generosa y compartir todos sus conocimientos y experiencias conmigo. He hecho muchos cambios en mi vida y 
  me siento mucho más ligera para ser quien soy y mas capaz de lograr lo que quiero. Todo estuvo siempre allí, pero no lo podía ver.`
  const TestName_6 = "Gaby Rubalcaba."

  // const Testimonio_7 = `En The Confidence Revolution aprendí muchas herramientas para aplicar en mi vida diaria que
  // me ayudan a sentirme más empoderada y con más confianza en mi misma y en lo que puedo hacer. Confieso que al principio s
  // e me hizo caro, pero ya viendo todos los beneficios que ha traído a mi vida, como se ha transformado y
  // como me siento hoy en comparación de cuando empecé, creo que es la mejor inversión que he hecho en mi misma en siglos!
  // Mi mejor recomendación es que si quieres invertir en ti misma, hagas este programa y veas tu misma la diferencia.
  // Muy agradecida contigo Ivone, Saludos.`
  // const TestName_7 = "Carla Fuentes."

  // const Testimonio_8 = `Al grano: te recomiendo a Ivone totalmente.
  // Si hubiera encontrado este programa antes me hubiera ahorrado mucha frustración
  // y mucho sufrimiento y hubiera logrado, todo lo que he logrado desde que empece este programa,
  // hace mucho tiempo. Lo que más me gusto es el enfoque de estrategia y acción, porque pude ver la diferencia en mi vida
  // inmediatamente. No lo pienses más, apúntate! Saludos Ivone!`
  // const TestName_8 = "Laura Adán"

  const Testimonio_9 = `¡Transformación total! Este programa marcó un antes y un después en mi vida!. 
  ¡Ojalá nos enseñaran todo esto en las escuelas!
    Hoy me siento confiada de mi misma como nunca antes lo había sentido. Hoy sé que puedo lograr todo lo que me proponga.`
  const TestName_9 = "Eva López"

  const Testimonio_10 = `Gracias a The Confidence Revolution, logré renunciar al trabajo con el que ya tenía años insatisfecha. 
    En mi nuevo trabajo en verdad siento que estoy aprovechando toda mi experiencia y me siento mucho más felíz. 
    Además ¡Todas mis amigas notaron el cambiaso que dí!.  Ahora me siento empoderada, capaz de lograr muchas cosas que 
    antes veía imposibles. ¡Gracias Ivone!`
  const TestName_10 = "Sandra Flores."

  return (
    <>
      <Navigation active={3} />
      <HeroSection
        id="Start Image"
        bgImage={HeroImage}
        overlay="full"
        className="tenor"
        h1="The Confidence Revolution"
        titleClassName="white"
      ></HeroSection>

      <Section color="turkis-blue1" small>
        <Article className="white center" layout="wide">
          {/* <div className="center font-size-medium lineheight-verylarge ">
            Solo trabajo con 5 mujeres a la vez.
          </div>
          <br />*/}
          <div className=" white tenor bold blue1 font-size-large lineheight-verylarge ">
            Reserva tu lugar
          </div>
          <Newsletter
            tag="PROGRAM"
            value="REVOLUTION"
            label="Lista de espera"
          />
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="bold blue1 font-size-verylarge"
          title="¿Te identificas con algunas de estas ideas?"
        >
          <Grid container spacing={1}>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Quizás ya has leído varios libros de desarrollo personal, has
                hecho visualizaciones y meditaciones y, sin embargo, todo eso
                que has aprendido no acaba de hacerte click y no logras
                aplicarlo del todo a tu vida.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Te sientes frustrada e insatisfecha con la vida que llevas
                actualmente, no las disfrutas en plenitud porque no estas
                haciendo lo que te gustaría.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Sientes que no estás utilizando tus capacidades y talentos al
                100% pero te gustaría poder hacerlo.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Sabes que puedes más, porque ya lo has demostrado antes, pero en
                este momento te sientes atorada, te falta claridad y no sabes
                por dónde empezar para cambiar esta situación.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Quizá tienes un trabajo que, aunque estable, ni te gusta ni te
                llena personal ni profesionalmente y que además, te deja super
                agotada.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Quisieras encontrar algo que te dé más satisfacción. Tienes
                ideas de lo que te gustaría hacer, pero no te atreves a empezar
                o no sabes cómo hacerlo.
              </BulletItem>
            </Grid>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Tal vez quieres empezar con tu propio negocio, pero tienes
                muchas dudas y miedos. Muchas veces piensas que “todavía no
                estas lista” o que “todavía no sabes lo suficiente” y todo eso
                te tiene paralizada.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Te sientes insegura y dudas de ti misma o de lo que podrías
                lograr. Te falta motivación.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Has estado largo tiempo en esta situación y, aunque por momentos
                logras olvidarla, otras veces se siente más fuerte, incluso como
                ansiedad y otras como miedo.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Dependes económicamente de tu pareja o te cuesta llegar a fin de
                mes con tantos gastos y eso afecta fuertemente tu autoestima.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Sientes dentro de ti esa necesidad urgente de hacer algo para
                transformar esa situación, y que el dinero empiece a llegar mas
                fácilmente.
              </BulletItem>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="turkis-blue1">
        <Article className="center bold playfair white font-size-large lineheight-verylarge ">
          Y por si fuera poco, la pandemia te hizo cuestionarte muchas cosas y
          <br />
          por eso llegaste a la conclusión de que...
        </Article>
      </Section>
      <Section>
        <Article className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
          ¡Éste es tu momento!
        </Article>
      </Section>

      <Section color="white">
        <Article className="center font-size-medium  lineheight-verylarge">
          <div className="center playfair turkis-blue1 bold font-size-medium">
            ¿Sientes fuertemente la necesidad de contribuir al mundo con tus
            talentos, <br /> con tus habilidades, con tu inteligencia y con tu
            experiencia?
          </div>
          <br />
          <div className="center blue1 bold font-size-medium">
            ¿Te imaginas disfrutar de lo que haces y que además eso te genere
            los ingresos que te mereces?
          </div>
          <br />
          <div className="center turkis-blue1 bold font-size-medium">
            ¿Te gustaría conectar con la mujer segura que vive dentro de ti y
            darle rienda suelta a todo tu potencial y emplearlo para conseguir
            eso que sueñas?
          </div>
          <br />
          <div className="center blue1 bold font-size-medium">
            ¿Quieres aprender a confiar en tu guía interior, conectar con tu
            intuición y con tu propósito de vida?
          </div>
          <br />
          <div className="bold playfair font-size-large">Entonces…</div>
        </Article>
      </Section>

      <Section color="white">
        <Article className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          ¡Estas en el lugar correcto!
        </Article>
        <Article className="center font-size-medium lineheight-verylarge">
          <br />
          Déjame decirte algo:
          <br />
          Si eres capaz de imaginar esa vida ideal para ti,
          <br />
          es porque también eres capaz de volverla realidad.
          <br /> <br />
        </Article>
        <Article className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
          Tú, tienes el poder y la capacidad para realizar todo eso que quieres.
        </Article>
      </Section>

      <Section color="beige1">
        <Article layout="wide">
          <About
            titleClassName="tenor bold blue1 font-size-verylarge  left lineheight-large"
            title="Soy Ivone Herrera"
            image={AboutImage}
          >
            <span className=" blue1 lineheight-verylarge  ">
              <strong></strong>
              En 2012 perdí todo y estuve al borde del{" "}
              <strong> suicidio </strong>. Sobreviví, pero durante años seguí
              sintiendome <strong> insatisfecha con mi vida, </strong>
              <strong> estancada, </strong> con{" "}
              <strong> ansiedad constante </strong> y con muchas{" "}
              <strong> dudas e incertidumbre. </strong>
              <br />
              <br />
              Mi intuición me decía que estaba en mis manos hacer algo radical
              para transformar mi mentalidad y generar ingresos.
              <br />
              <br />
              <strong>
                Entonces recordé que años antes, yo era una mujer segura y
                confiaba de tal manera en mí misma, que era capaz de conseguirlo
                todo.
              </strong>
              <br />
              <br />
              No conocía imposibles ni excusas y podía materializar todo lo que
              yo quisiera. Estaba segura de que, si una fue así, podía volver a
              serlo.
              <br />
              <br />
            </span>
            <div className="bold center turkis-blue1  font-size-medium lineheight-verylarge">
              ¿Qué elementos hacían la diferencia entre la sensación de poderlo
              todo y la desesperación total? ¿Cuál era el secreto?
              <br />
              <br />
            </div>
            <span className=" blue1 lineheight-large ">
              Después de reconstruirme nuevamente en una mujer poderosa y
              confiada de mi misma,{" "}
              <strong>te lo puedo decir con certeza.</strong>
              <br />
              <br />
              Además decidí formarme como coach y mentora para poder enseñarlo a
              más mujeres y para que puedan aplicarlo a su vida.
              <br />
              <br />
              Y precisamente por eso me estas leyendo ahora.
              <br />
              <br />
              <span className="bold left turkis-blue1 font-size-medium lineheight-large">
                Gracias a todo lo que he vivido y aprendido en este camino,
                estoy preparada y lista para compartirlo contigo.
              </span>
            </span>
          </About>
        </Article>
      </Section>

      {/* <Section color="white">
        <Article className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
        Tu también mereces conectar con esa confianza y seguridad tuyas que te permitirán alcanzar todo lo que te propongas
          <br/><br/>
        </Article>
      </Section>
      */}
      <Section
        color="blue"
        bgImage={ImageAreas}
        overlay="full"
        overlayColor="rgba(250, 246, 246, .6)"
        //overlayColor="rgba(98, 128, 174, .8)"
      >
        <Article
          layout="narrow"
          title=" Tu también mereces conectar con esa confianza y seguridad tuyas que te
          permitirán alcanzar todo lo que te propongas"
          titleClassName="blue1 playfair bold center font-size-verylarge  lineheight-verylarge"
        >
          <br />
          <br />
        </Article>
        <Article>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <Card
                avatar={ImageSensibilidad}
                titleClassName="tenor bold center font-size-medium"
                title="Profesionalmente"
              >
                <BulletItem icon={bulletIcon2}>
                  Reinvéntarte profesionalmente.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Incrementar tus ingresos.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card
                avatar={ImageEstrategia}
                titleClassName="tenor bold center font-size-medium"
                title="Personalmente"
              >
                <BulletItem icon={bulletIcon2}>
                  Sentirte segura de tus talentos y capacidades.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Comunícarte con seguridad.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card
                avatar={ImageAccion}
                titleClassName="tenor bold center font-size-medium"
                title="Emocionalmente"
              >
                <BulletItem icon={bulletIcon2}>
                  Lograr mayor balance emocional.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Romper la dependencia emocional.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card
                avatar={ImageEquipo}
                titleClassName="tenor bold center font-size-medium"
                title="Físicamente"
              >
                <BulletItem icon={bulletIcon2}>
                  Mejorar tu imagen y condición física.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>Mejorar tu salud.</BulletItem>
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="white">
        <Article className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          Yo sé todo lo que como mujer tienes para ofrecerle al mundo
        </Article>
        <Article className="center font-size-medium lineheight-verylarge">
          <br />
          y es mi deber ayudarte a que lo compartas,
          <br /> <br />
        </Article>
        <Article className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
          para que con ello generes los ingresos que te mereces y crees esa vida
          que sueñas.
        </Article>
      </Section>

      <Section
        color="beige1"
        /* bgImage={ImageCircularWaves}
        overlay="full"
        // overlayColor="rgba(250, 246, 246, .2)"
        overlayColor="rgba(98, 128, 174, .3)" */
        layout="narrow"
      >
        <Article>
          <Card
          //titleClass="bold center tenor font-size-verylarge blue1"
          //Title= "Por eso existe mi programa 'The Confidence Revolution'"
          >
            <span className="bold center tenor font-size-verylarge blue1 lineheight-verylarge">
              Por eso existe mi programa 'The Confidence Revolution'
              <br />
              <br />
            </span>
            <BulletItem icon={bulletIcon1}>
              Este programa te proporcionará todos los{" "}
              <strong> conocimientos, herramientas y prácticas </strong> para
              que te conviertas en la artista y creadora de tu vida.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Mi programa te ayudará a{" "}
              <strong>
                {" "}
                conectar con tu intuición, reconocer tu guía interior y entender
                tus emociones.
              </strong>
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Además te orientará para que encuentres{" "}
              <strong>tu perfecto balance </strong> entre
              <strong> emoción, estrategia y acción.</strong>
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Y te permitirá{" "}
              <strong>
                {" "}
                reconquistar la confianza y seguridad en ti misma,
              </strong>{" "}
              que te conectarán con tu verdad y{" "}
              <strong>
                tu poder personal para lograr todo lo que quieras.
              </strong>
            </BulletItem>
          </Card>
        </Article>
      </Section>

      <Section
        color="turkis-blue1"
        bgImage={ImageDistinto}
        overlay="full"
        overlayColor="rgba(250, 246, 246, .4)"
      >
        <Article
          title="The Confidence Revolution es distinto de otros programas porque:"
          titleClassName="bold blue1 tenor font-size-verylarge lineheight-large "
          layout="narrow"
        >
          <NumericList text={numericListText} />
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="blue1 tenor font-size-verylarge"
          title="Testimonios"
        >
          <Carousel dots arrows slidesToShow={2} slidesToScroll={1}>
            <Testimonial name={TestName_6} variation="small">
              {" "}
              {Testimonio_6}{" "}
            </Testimonial>
            <Testimonial name={TestName_4} variation="small">
              {" "}
              {Testimonio_4}{" "}
            </Testimonial>
            <Testimonial name={TestName_5} variation="small">
              {" "}
              {Testimonio_5}{" "}
            </Testimonial>
          </Carousel>
        </Article>
      </Section>

      <Section
        color="blue"
        bgImage={ImageOceanBlue}
        overlay="full"
        overlayColor="rgba(98, 128, 174,.2)"
      >
        <Article className="center">
          <span className="playfair  bold white center font-size-verylarge  lineheight-extralarge">
            ¿Quieres saber cómo lo haremos?
          </span>
          <br /> <br />
          <span className="center white font-size-medium">
            {" "}
            Mi método combina{" "}
          </span>
          <span className="bold white font-size-medium">
            {" "}
            sensibilidad, estrategia y acción.{" "}
          </span>
          <br /> <br />
          <br /> <br />
        </Article>
        <Article>
          <Grid container spacing={3} justify="center">
            <Grid item md={3} sm={6} xs={10}>
              {/*  <Card icon={icon} title="Sensibilidad" className="center">
              Aprende a interpretar tus emociones para que sepas lo que necesitas hacer en cada momento.
              </Card> */}
              <Card
                avatar={ImageSensibilidad}
                titleClassName="bold tenor font-size-medium"
                title="Sensibilidad"
                className="center"
              >
                Aprende a interpretar tus emociones para que sepas lo que
                necesitas hacer en cada momento.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={<FaChess size="36px" />}title="Estrategia" className="center">
              Aprende a reajustar tus acciones conscientemente para que te lleven más cerca de tus metas.
              </Card> */}
              <Card
                avatar={ImageEstrategia}
                titleClassName="bold tenor font-size-medium"
                title="Estrategia"
                className="center"
              >
                Aprende a reajustar tus acciones conscientemente para que te
                lleven más cerca de tus metas.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={icon} title="Acción" className="center"> */}
              <Card
                avatar={ImageAccion}
                titleClassName="bold tenor font-size-medium"
                title="Acción"
                className="center"
              >
                Toma acción con intención y empieza a ver y disfrutar de
                resultados tangibles rápidamente.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={icon} title="Somos un equipo" className="center"> */}
              <Card
                avatar={ImageEquipo}
                titleClassName="bold tenor font-size-medium"
                title="Somos un equipo"
                className="center"
              >
                Soy tu confidente y la voz de apoyo que necesitas para surfear
                los desafíos que se te presentan.
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="beige1">
        <Article
          layout="wide"
          titleClassName=" playfair bold  blue1 font-size-verylarge lineheight-verylarge "
          title="Esto es lo que podrás lograr con mi programa The Confidence Revolution"
        >
          <ListCard
            title={TitleConfianza}
            titleClassName=" tenor  blue1 font-size-large"
            text={ListConfianza}
            //image={IvoneImage}
            listItemIcon={bulletIcon3}
          />
          <ListCard
            title={TitleDudas}
            titleClassName=" tenor  blue1 font-size-large"
            text={ListDudas}
            //image={IvoneImage}
            listItemIcon={bulletIcon3}
          />
          <ListCard
            title={TitleMetas}
            titleClassName=" tenor  blue1 font-size-large"
            text={ListMetas}
            // image={IvoneImage}
            listItemIcon={bulletIcon3}
          />
          <ListCard
            title={TitleEmociones}
            titleClassName=" tenor  blue1 font-size-large"
            text={ListEmociones}
            // image={IvoneImage}
            listItemIcon={bulletIcon3}
          />
          <ListCard
            title={TitleTalentos}
            titleClassName=" tenor  blue1 font-size-large"
            text={ListTalentos}
            // image={IvoneImage}
            listItemIcon={bulletIcon3}
          />
        </Article>
      </Section>

      <Section color="white">
        <div className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          En pocas palabras vas a enamorarte de ti misma,
          <br /> de todo lo que eres y puedes lograr.
          <br />
          <br />
        </div>
        <div className="center font-size-medium lineheight-verylarge">
          Además te sentirás poderosa, con confianza y estarás segura de que
          eres
          <br />
          capaz de lograr TODO lo que te propongas,
          <br />
          sin importar los obstáculos ni las limitaciones que se te presenten.
          <br />
          <br />
        </div>
        <div className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
          Ese es TU poder personal y ha estado siempre dentro de ti.
        </div>
        <div className="center font-size-medium lineheight-verylarge">
          Yo te ayudo y te guío para que lo actives y lo puedas utilizar en toda
          su potencia.
        </div>
      </Section>

      <Section color="turkis-blue1">
        <Article
          layout="wide"
          titleClassName="white tenor font-size-verylarge"
          title="Testimonios"
          className="center"
        >
          <Testimonial
            name={TestName_10}
            variation="small"
            // image={IvoneImage}
          >
            {" "}
            {Testimonio_10}
          </Testimonial>
        </Article>
      </Section>

      {/* asdjasdjahdjabdj */}
      <Section color="beige1">
        <Article
          layout="narrow"
          titleClassName=" tenor  blue1 bold font-size-extralarge lineheight-verylarge"
          title="¿Qué incluye The Confidence Revolution?"
        >
          <ListCard text={List_Incluye} listItemIcon={bulletIcon4}></ListCard>
        </Article>
      </Section>

      <Section color="blue">
        <Article layout="wide">
          <Carousel dots arrows slidesToShow={1} slidesToScroll={1} fade>
            <Testimonial name={TestName_3}> {Testimonio_3} </Testimonial>
            <Testimonial name={TestName_2}> {Testimonio_2} </Testimonial>
            <Testimonial name={TestName_1}> {Testimonio_1} </Testimonial>
          </Carousel>
        </Article>
      </Section>

      <Section color="white">
        <Article>
          <div className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
            The Confidence Revolution <br />
            es mucho más que un simple curso digital o sesiones de coaching.
          </div>
          <br />
          <div className="narrow  center font-size-medium lineheight-verylarge">
            Es un conjunto de herramientas y prácticas que transformaran tu
            mentalidad,
            <br />
            la manera en que te valoras a ti misma y la manera en que actúas en
            tu día a día.
            <br />
            <br />
          </div>
          <div className="narrow playfair bold center font-size-large blue1 lineheight-verylarge">
            Aprenderás una nueva forma de enfrentarte al mundo
            <br /> sin sentir que eres víctima de las circunstancias.
            <br />
            <br />
          </div>
        </Article>
      </Section>

      <Section color="beige1">
        <Article>
          <div className="narrow  turkis-blue1 playfair bold center font-size-verylarge  lineheight-verylarge">
            The Confidence Revolution <br /> marcará un antes y un después en tu
            vida.
            <br />
            <br />
          </div>
          <div className="narrow   center font-size-medium lineheight-verylarge">
            Cuando termines el programa estarás capacitada para
            <br /> enfrentarte con placer y emoción a los desafíos que se te
            presenten.
            <br />
            <br />
          </div>
          <div className="narrow playfair bold  center font-size-large blue1 lineheight-verylarge">
            Además podrás recurrir a estas poderosas herramientas de por vida y
            <br />
            cada vez te será más fácil materializar el estilo de vida que
            anhelas.
          </div>
          <br />
          <br />
        </Article>
      </Section>

      <Section color="white">
        <Article>
          <div className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
            Lo aprendido en este programa <br /> se convertirá en tu nuevo
            estilo de vida.
            <br />
            <br />
          </div>
          <div className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
            ¡Te va a encantar!
          </div>
        </Article>
      </Section>

      <Section color="turkis-blue1">
        <Article
          layout="wide"
          titleClassName="white raleway bold center font-size-large  lineheight-verylarge "
          title={
            <div>
              ¿Te imaginas ser capaz conseguir todo lo que quieres y que <br />{" "}
              a veces no te atreves ni a contar?
              <br />
              ¿Te imaginas que la inversión sea sólo de...?
            </div>
          }
        >
          <Grid container spacing={3} justify="center">
            <Grid item md={4}>
              <Card
                icon={icon}
                className="center tenor font-size-large  lineheight-verylarge"
              >
                1 pago único de
                <br />
                <div className="center playfair font-size-large  lineheight-verylarge">
                  750€
                </div>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card
                icon={icon}
                className="center tenor font-size-large  lineheight-verylarge"
              >
                3 cuotas de
                <div className="center playfair font-size-large  lineheight-verylarge">
                  257€
                </div>
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="white">
        <Article className="center" layout="wide">
          <div className="center tenor bold blue1 font-size-large lineheight-verylarge ">
            Reserva tu lugar aquí:
          </div>
          <Newsletter
            tag="PROGRAM"
            value="REVOLUTION"
            label="Lista de espera"
            labelBgColor="turkis-blue1"
            labelColor="white"
          />
          <br />
          {/* <div className="center font-size-medium lineheight-verylarge ">
            Solo trabajo con 5 mujeres a la vez. Por el momento hay{" "}
            <span className="bold tenor turkis-blue1 font-size-medium ">
              {" "}
              0{" "}
            </span>{" "}
            lugares disponibles.
          </div>
          <div className="center font-size-medium lineheight-verylarge ">
            En cuanto haya un cupo disponible me pondré en contacto contigo.
          </div> */}
        </Article>
      </Section>

      <Section color="beige1" id="ES-PARA-TI">
        <Article layout="wide">
          <div className="center playfair bold blue1 font-size-verylarge lineheight-verylarge ">
            ¿Cómo saber si este programa es para ti?
            <br />
            <br />
          </div>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <ListCard
                titleClassName="tenor bold turkis-blue1 font-size-large "
                title={Title_SI}
                text={List_SI}
                listItemIcon={<BsCheckCircle className="turkis-blue1" />}
              />
            </Grid>
            <Grid item md={6}>
              <ListCard
                titleClassName="tenor bold turkis-blue1 font-size-large "
                title={Title_NO}
                text={List_NO}
                listItemIcon={<BsCheckCircle className="turkis-blue1" />}
              />
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="white">
        <Article className="center">
          <div className="narrow playfair bold center font-size-large turkis-blue1 lineheight-verylarge">
            Se trata de un programa totalmente personalizado en el que
            <br />
            te dedicaré toda mi atención y compromiso profesional.
            <br />
            <br />
          </div>
          <div className="center font-size-medium lineheight-verylarge">
            Por eso, si trabajamos juntas,
            <br />
            es necesario que tú también estes seriamente comprometida con tus
            resultados.
            <br />
            <br />
          </div>
          <div className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
            ¿Estas lista para experimentar esta poderosa transformación?
          </div>

          {/* <div className="center  tenor turkis-blue1 font-size-large lineheight-verylarge">
          Aplica aquí:
          </div>
          <div className="pink center">
            <br />
            <CtaButton label="Quiero Empezar ¡Ahora mismo!" disabled />
          </div> */}
          <br />
          <div className="center font-size-medium lineheight-verylarge ">
            Solo trabajo con 5 mujeres a la vez.
            {/* //Por el momento hay{" "} */}
            {/* <span className="bold tenor turkis-blue1 font-size-medium ">
              {" "}
              0{" "}
            </span>{" "}
            lugares disponibles. */}
          </div>
          <br />
          <div className="center tenor bold turkis-blue1 font-size-large lineheight-verylarge ">
            Reserva tu lugar aquí:
          </div>

          <br />
          <Newsletter
            tag="PROGRAM"
            value="REVOLUTION"
            label="Lista de espera"
            labelBgColor="turkis-blue1"
            labelColor="white"
          />

          <div className="center blue1 font-size-medium lineheight-verylarge ">
            En cuanto haya un lugar disponible me pondré en contacto contigo.
          </div>
        </Article>
      </Section>

      <Section color="blue" id="Lista-de-espera">
        <Article layout="wide"></Article>
      </Section>

      <Section color="turkis-blue1">
        <Article
          layout="wide"
          titleClassName="center white tenor font-size-large lineheight-verylarge "
          title="Testimonios"
        >
          <Testimonial
            name={TestName_9}
            variation="small"
            // image={IvoneImage}
          >
            {" "}
            {Testimonio_9}{" "}
          </Testimonial>
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="narrow"
          titleClassName="center tenor blue1 font-size-large lineheight-verylarge "
          title="Preguntas Frecuentes"
        >
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              ¿Cuánto tiempo tendré que dedicarle?
            </AccordionSummary>
            <AccordionDetails>
              Necesitarás una dedicación mínima de tres horas a la semana, de
              las cuales una será para la sesión de coaching en directo y dos
              mas para revisar los contenidos del programa y realizar los
              ejercicios.
              <br />
              Los ejercicios están confeccionados para guiarte con facilidad, de
              modo que irás obteniendo resultados según vayas avanzando. Lo
              mejor es que los hagas en diversos momentos durante de la semana,
              en pequeños bloques de 15 a 20 min cada uno.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              ¿Qué pasa si no tengo tiempo para hacer los retos?
            </AccordionSummary>
            <AccordionDetails>
              Los retos serán graduales, empezaremos en pequeño e iremos
              construyendo con el avance. Estarán enfocados a realizar cambios
              necesarios en tu vida y es fundamental que te comprometas a
              realizarlos.
              <br></br>
              No te preocupes, yo sé que habrá momentos en que te sientas
              bloqueada, y para eso estoy yo aquí, para descubrir dónde esta el
              problema y ayudarte a superarlo. Yo te brindaré el acompañamiento
              que necesitas para enfrentar tus desafíos y mantenerte enfocada y
              avanzando.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              ¿Qué pasa si no puedo ver los contenidos o hacer los ejercicios a
              tiempo?
            </AccordionSummary>
            <AccordionDetails>
              Para ver resultados es necesario que te comprometas a revisar los
              contenidos y hacer los ejercicios en el tiempo indicado para poder
              avanzar en el programa. Durante las sesiones de coaching vamos a
              revisar y comentar las reflexiones obtenidas de los ejercicios.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              ¿Qué pasa si no puedo asistir a las sesiones de coaching?
            </AccordionSummary>
            <AccordionDetails>
              Las sesiones de coaching las agendamos al inicio del programa. Si
              requieres hacer un cambio puedes hacerlo con una semana de
              anticipación a la sesión correspondiente para establecer una nueva
              fecha. En caso de que no se realice el aviso a tiempo, la sesión
              de coaching se dará por cancelada y no habrá oportunidad de
              reagendar.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              ¿Y si tengo dudas?
            </AccordionSummary>
            <AccordionDetails>
              Si tienes dudas durante el programa solo tienes que plantearlas en
              las sesiones de coaching en directo o en el chat de Telegram/
              Whatsapp. El chat te servirá, además, para compartir tus
              experiencias, dificultades, bloqueos y logros durante el camino.
              ¡Utilízalo!
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              ¿En cuánto tiempo empezaré a ver resultados?
            </AccordionSummary>
            <AccordionDetails>
              Esto depende de qué tan comprometida estés para realizar cambios
              en tu vida. Es fundamental que traigas una mente abierta para
              integrar nuevas ideas y perspectivas. Si es así, desde el primer
              mes sentirás la diferencia.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              ¿No es un poco caro?
            </AccordionSummary>
            <AccordionDetails>
              La respuesta depende de que tan importante te consideras tú a ti
              misma y en cuánto estimas el valor de tu felicidad. Lo que sí te
              puedo decir es que todas mis clientas están satisfechas.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              ¿Qué pasa al terminar el programa quiero continuar con mas
              sesiones de coaching?
            </AccordionSummary>
            <AccordionDetails>
              Estaré más que encantada de seguir acompañándote en tu camino. Y
              como ya hemos trabajado juntas el avance será más sencillo. Además
              te daré un precio preferente para las sesiones que necesites.
            </AccordionDetails>
          </Accordion>
        </Article>
      </Section>
      <Footer />
    </>
  )
}
